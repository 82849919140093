import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCaretLeft,
  faCaretRight,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import router from './router'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { createProvider } from './vue-apollo'
import VueI18n from 'vue-i18n'
import i18n from './i18n'

library.add(faCaretLeft)
library.add(faCaretRight)
library.add(faQuestionCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueI18n)

new Vue({
  i18n,
  router,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app')
