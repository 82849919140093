<template>
  <div class="container mt-3 mb-2">
    <nav class="navbar navbar-expand navbar-light">
      <a class="navbar-brand" :href="$i18n.locale === 'cn' ? '/cn' : '/'">
        <img v-if="logo==='taobase'" src="../assets/taobaseLogo.png" />
        <img v-else src="../assets/ailawLogo.png" />
      </a>
      <div class="navbar-nav ml-auto my-2 font-weight-bold">
        <a
          class="nav-link ml-auto px-lg-5 text-decoration-none clickable"
          :href="$i18n.locale === 'cn' ? '/cn' : '/'"
        >{{ $t('header.home') }}</a>
        <a
          class="nav-link ml-auto px-lg-5 text-decoration-none clickable"
          :href="$i18n.locale === 'cn' ? '/cn/package' : '/pricing'"
        >{{ $t('header.pricing') }}</a>
        <a
          class="nav-link ml-auto px-lg-5 text-decoration-none clickable"
          href="https://blog.corplaw.ai"
        >{{ $t('header.blog') }}</a>
      </div>
    </nav>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'Navi',
  data() {
    return {
      logo: Cookies.get('PID'),
    }
  },
  watch: {
    $route() {
      if (this.$route.query.PID) {
        Cookies.set('PID', this.$route.query.PID)
      }
      this.logo = Cookies.get('PID')
    },
  },
}
</script>

<style scoped lang="scss">
.nav-link {
  cursor: pointer;
}

img {
  height: 2.5rem;
}

.corplaw {
  font-size: 1.5rem;
}

@include media-breakpoint-up(lg) {
  img {
    height: 4rem;
  }

  .corplaw {
    font-size: 2.5rem;
  }
  .navi-size {
    font-size: 1.25rem;
  }
}
</style>
