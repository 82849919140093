import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'navigator-active',
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/caselist',
      component: () => import('@/views/CaseList'),
    },
    {
      path: '/',
      component: () => import('@/views/Home'),
    },
    {
      path: '/nr',
      component: () => import('@/views/Home-nr'),
    },
    {
      path: '/pricing',
      component: () => import('@/views/Pricing'),
    },
    {
      path: '/pricing-cn',
      component: () => import('@/views/Pricing-cn'),
    },
    {
      path: '/blog',
      component: () => import('@/views/Blog'),
    },
    {
      path: '/check-out',
      component: () => import('@/views/Checkout'),
    },
    {
      path: '/disclaimer',
      component: () => import('@/views/Static/Disclaimer'),
    },
    {
      path: '/success',
      component: () => import('@/views/Success'),
    },
    {
      path: '/terms',
      component: () => import('@/views/Static/Terms'),
    },
    {
      path: '/policy',
      component: () => import('@/views/Static/Policy'),
    },
    {
      path: '/order-review',
      component: () => import('@/views/OrderReview'),
    },
  ],
})
