<template>
    <div id="app">
        <div class="container-fluid p-0">
            <Navi/>
            <router-view/>
            <Footer/>
        </div>
    </div>
</template>

<script>
  import Navi from './components/Navi'
  import Footer from './components/Footer'

  export default {
    components: {
      Navi,
      Footer
    }
  }
</script>

<style lang="scss">
    #app {
        font-family: Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: rgba(54, 77, 110, 1.0);
    }
    #app a {
        color: rgba(54, 77, 110, 1.0);
    }

    .clickable {
        cursor: pointer;
    }

    .clickable:hover {
        background-color: $theme-background-color;
    }
</style>
