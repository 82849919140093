import Vue from 'vue'
import I18n from 'vue-i18n'
import messages from './messages'

window.Vue = Vue

const i18n = new I18n({
  locale: 'en',
  messages,
})

export default i18n
