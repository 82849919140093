const en = {
  contact: {
    title: 'Contact Information',
    firstName: 'First Name',
    firstNameRequired: 'Valid first name is required.',
    lastName: 'Last Name',
    lastNameRequired: 'Valid last name is required.',
    email: 'Email',
    phoneNumber: 'Phone Number',
    phoneNumberPlaceholder: '012-345-6789',
  },
  company: {
    title: 'Company Information',
    name: 'Name',
    companyName: 'Company Name',
    companyAddress: 'Company Address',
    designator: 'Designator',
    industry: 'Business Type / Industry',
    healthCare: 'Health care & social assistance',
    broker: 'Wholesale-agent / broker',
    construction: 'Construction',
    rental: 'Rental & leasing',
    transportation: 'Transportation & warehousing',
    accommodation: 'Accommodation & food service',
    wholesale: 'Wholesale-other',
    retail: 'Retail',
    estate: 'Real estate',
    manufacturing: 'Manufacturing',
    finance: 'Finance & insurance',
    other: 'Other (specify)',
    purpose: 'Business Purpose',
    addressInformation: 'Company Address Information',
    california:
      'California guidelines require all Corporations/LLCs to provide a company address that is located within the State of California.<br />To help clients meet the in-state address requirement and maintain the privacy of their personal address we offer a business address to use in the articles of incorporation.',
    delaware:
      'Delaware guidelines do not require or list a business address in the articles of organization. However the names and addresses of the members are recorded and made publicly available.<br />In order to assist clients maintain the privacy of their personal addresses we offer a business address which can be used to list on the articles of organization.',
    ownAddress: 'Use My Own Address',
    ownAddress1:
      'I will provide my own {state} business address and will personally keep up with any incoming mail.',
    ownAddress2InDe:
      'Delaware requires a physical street address (P.O Boxes are not accepted).',
    ownAddress2InCa:
      'I understand California requires an in-state physical street address (P.O Boxes are not accepted).',
    ownAddress3:
      'I understand that the address provided to the state will be publicly available.',
    useContactAddress: 'Use Contact Address',
    useCompanyAddress: 'Use Company Address',
    useOwnAddress: 'Use Own Address',
    virtualAddress: 'Office Address & Virtual Mail Service',
    virtualAddress1: 'Your principal company address will be:',
    virtualAddress2: 'Bill annually. Renew automatically next year.',
    directorInfo: 'Director Information',
    numberOfDirectors: 'Number of Directors',
    director: 'Director',
    stockInfo: 'Corporate Stock Information',
    numberOfSharesAuthorized: 'Number of Shares Authorized',
    shares: 'Company Shares',
    sharesContent:
      'The ownership of a corporation is vested in shares, which are distributed to the owners in proportion to their ownership. The number of initial shares your corporation is authorized to distribute is specified in the Articles of Incorporation and must be greater than zero. The actual number is more or less arbitrary, at your discretion. CORPLAW uses a default number of 1500 shares (this is due to the fact that 1500 is easily divisible by 2, 3, 4); with a par value of one cent (if your state requires par value, otherwise no par value will be assigned). Some states charge more to form a corporation with a high number of shares and/or high par value.',
    shareParValue: 'Share Par Value',
    parValue: 'Par Value',
    parValueContent:
      'Par value is a nominal dollar amount given to corporate shares. It doesn’t necessarily reflect their real value, and is typically set at a low value (i.e. one dollar or one cent). The par value of a share is the minimum price at which it may be sold to shareholders, and the par value must be the same for all shares of the same class. The shares can be sold to the initial shareholders, at par value or more, but the price must be the same for each share. Not all states require a par value. Unless you specify otherwise, CORPLAW will authorize 1500 shares (this is due to the fact that 1500 is easily divisible by 2, 3, 4, 5, 6) with a par value of one cent, or at no par value if not required by your state.',
    numberOfShareholders: 'Number of Shareholders',
    shareholders: 'What is a shareholders',
    shareholdersContent:
      'The shareholders of a corporation are the owners of the corporation and exercise ultimate authority over the corporation, and they are responsible on an ongoing basis for electing/appointing the board of directors to oversee the long term management of the corporation. Only one shareholder is required to form a corporation. The ownership of a corporation is vested in shares of stock of the corporation, and the shareholders are issued shares in proportion to their ownership.',
    taxInfo: 'Tax Registration Information',
    fictitiousName: 'Fictitious Name',
    businessLocated: 'County and state where principal business is located',
    nameOfResponsibleParty: 'Name of responsible party',
    noSSN:
      'I do not have SSN/ITIN (FEIN application processing time will be longer if you do not provide SSN)',
    startedDate: 'Date business started or acquired',
    closedMonth: 'Closing month of an accounting year',
    memberInfo: 'Member Information',
    numberOfMember: 'Number of Members/Owners',
    member: 'Member',
    individualOrCompany: 'Is this member an individual or a company?',
    individual: 'Individual',
    company: 'Company',
    representative: 'Representative signing on behalf of entity',
    addressOfMember: 'Address for this member',
    memberManager: 'Member/Manager Managed LLC',
    memberManagerContent:
      'There are two general methods by which an LLC may be managed: either Member-Managed or Manager-Managed, and the state requires us to specify the management method when an LLC is formed. <br />Please tell us the management structure you intend to have for your LLC initially.',
    memberManager1:
      'The LLC will be managed by the LLC member(s) (Most Common)',
    memberManager2: 'The LLC will be managed by One Manager',
    memberManager3: 'The LLC will be managed by More than One Manager',
  },
  agent: {
    title: 'Registered Agent Information',
    content1:
      'The function of the agent is to accept official legal and tax correspondence from the state of formation (such as service of process, litigation notices, etc.), and to pass it on to you. Items that are not from the state of formation will not be accepted, except for junk mail, which will be discarded.',
    content2:
      'The Agent address must be a street address in the state of formation; US Postal Service P.O. Boxes are not accepted by the state.',
    content3:
      'Every state requires a corporation or an LLC to have a Registered Agent (called by some states Resident Agent, Statutory Agent, or Agent for Service of Process.',
    content4:
      'Your order is eligible for 1 full year of FREE registered agent services. After the initial year, an annual fee of $99 will apply.',
  },
  optionalService: {
    title: 'Optional Service',
    initial: 'Initial Statement of Information',
    initialHint:
      'It is required to file your initial Statement of Information within 90 days after your registration.',
    serviceFee: 'Service Fee',
    governmentFee: 'Government Filing Fee',
    trademark: 'Trademark Registration',
    trademarkHint: 'We will contact you for your trademark name check.',
    category: 'Category',
  },
  review: {
    title: 'ORDER REVIEW',
  },
  address: {
    address: 'Address',
    address2: 'Address 2',
    country: 'Country',
    state: 'State/Province',
    city: 'City',
    zip: 'Zip',
  },
  payment: {
    title: 'Payment Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    billingAddress: 'Billing Address',
    cardType: 'Credit or debit card',
    info: 'Payment Information',
    agreePolicy:
      'I agree to the <a href="/disclaimer">Disclaimer and Cancellation Policy</a>.',
    pay: 'COMPLETE & PAY',
  },
  order: {
    title: 'Order Summary',
    total: 'Total',
  },
  message: {
    optional: '(Optional)',
    choose: 'Choose',
    ca: 'California',
    de: 'Delaware',
    perYear: 'per year',
  },
  pagination: {
    back: 'BACK',
    next: 'NEXT STEP',
    review: 'REVIEW ORDER',
    update: 'UPDATE',
    edit: 'EDIT',
    payment: 'NEXT TO PAYMENT',
    step: 'step {current} of {total}',
  },
  header: {
    home: 'Home',
    pricing: 'Pricing',
    blog: 'Blog',
  },
  footer: {
    contactUs: 'Contact Us',
    tel: 'Tel',
    business: 'Business Info',
    technical: 'Technical Info',
    legal: 'Privacy & Legal',
    terms: 'Terms of Use',
    policy: 'Privacy Policy',
  },
}

export default en
