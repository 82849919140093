<template>
  <div class="footer">
    <footer class="my-lg-5">
      <div class="container">
        <div class="row text-left justify-content-around">
          <div class="col-lg-4 mb-2 mb-lg-0">
            <div class="logo font-weight-bold mb-lg-3">CorpLaw</div>
            <div class="clickable" @click="$router.push('/nr')">For Non-US Resident</div>
            <div class="clickable" @click="onChineseClick">华语客户</div>
            <p>160 E Tasman Dr, San Jose, CA, 95134</p>
          </div>
          <div class="col-lg-4 mt-3 mt-lg-0">
            <div class="font-weight-bold mb-lg-3 my-2 sec-title">{{ $t('footer.contactUs') }}</div>
            <ul class="list-unstyled">
              <li>
                {{ $t('footer.tel') }}:
                <a class="text-muted" href="tel:650-396-3985">650-396-3985</a>
              </li>
              <li>
                {{ $t('footer.business') }}:
                <a
                  class="text-muted"
                  href="mailto:business@corplaw.ai"
                >corplaw@ailaw.ai</a>
              </li>
              <li>
                {{ $t('footer.technical') }}:
                <a
                  class="text-muted"
                  href="mailto:support@corplaw.ai"
                >support@ailaw.ai</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 mt-lg-0">
            <div class="font-weight-bold mb-lg-3 my-2 sec-title">{{ $t('footer.legal') }}</div>
            <ul class="list-unstyled mb-4">
              <li>
                <a class="text-muted" href="/terms">{{ $t('footer.terms') }}</a>
              </li>
              <li>
                <a class="text-muted" href="/policy">{{ $t('footer.policy') }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md text-center">
          <small class="d-block mb-3 text-muted copyright">
            Copyright © 2020 CorpLaw Inc.
            <span class="text-nowrap">All rights reserved</span>
          </small>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    onChineseClick() {
      location.href = '/cn'
    },
  },
}
</script>

<style scoped lang="scss">
.copyright {
  margin: 3rem;
}

.social {
  height: 1.75rem;
}

@include media-breakpoint-up(lg) {
  .logo {
    font-size: 1.25rem;
  }
  .sec-title {
    font-size: 1.25rem;
  }
}
</style>
