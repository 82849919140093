const cn = {
  contact: {
    title: '联系方式',
    firstName: '名（拼音或者拼写）',
    firstNameRequired: '名为必填项',
    lastName: '姓氏（拼音或者拼写）',
    lastNameRequired: '姓氏为必填项',
    email: '电邮（E-mail）',
    phoneNumber: '电话号码',
    phoneNumberPlaceholder: '+86 12345678909/+1 123-234-3456',
  },
  company: {
    title: '公司信息',
    name: '名称',
    companyName: '公司名称',
    companyAddress: '公司地址',
    designator: '公司后缀',
    industry: '从业类型/所在行业',
    healthCare: '保健和社会救助',
    broker: '批发代理商/经纪',
    construction: '建筑',
    rental: '房地产租赁',
    transportation: '运输仓储',
    accommodation: '食宿服务/旅馆业',
    wholesale: '批发-其他',
    retail: '零售',
    estate: '房地产',
    manufacturing: '制造业',
    finance: '金融&保险',
    other: '其他行业（请注明）',
    purpose: '公司业务',
    addressInformation: '公司注册地址信息',
    california:
      '加利福尼亚州要求所有股份公司（Corp）/有限责任公司（LLC）提供位于加州境内的公司地址。<br />考虑到加州法律要求与客户隐私需求，CORPLAW可提供公司注册地址服务于您的公司章程。',
    delaware:
      '特拉华州公司注册规则中并未要求登记您的注册公司地址。但是公司成员的姓名和地址需记录并公示。<br />对于有相关隐私需求的客户，CORPLAW可为您提供用于公司登记的商业地址。',
    ownAddress: '我坚持使用个人地址用于注册公司',
    ownAddress1:
      '我将提供我个人的{state}商用地址，并会主动跟进政府寄回的所有相关邮件。',
    ownAddress2InDe:
      '特拉华州公司注册中对地址的要求为真实街道地址（不接受邮箱地址）。',
    ownAddress2InCa:
      '我理解加利福尼亚州要求公司地址为真实街道地址（不接受邮箱地址）。',
    ownAddress3: '我理解并接受我的私人地址一经登记将被公示。',
    useContactAddress: '使用联络地址',
    useCompanyAddress: '使用公司地址',
    useOwnAddress: '使用个人地址',
    virtualAddress: 'CORPLAW 公司地址和虚拟邮箱服务',
    virtualAddress1: '购买服务后，您的公司注册地址为：',
    virtualAddress2: '年度收费。第二年自动续费。',
    directorInfo: '公司管理层登记',
    numberOfDirectors: '管理层人数（1-6）',
    director: '管理层',
    stockInfo: '股权分配',
    numberOfSharesAuthorized: '额定股本',
    shares: '新注册公司股权分配',
    sharsContent:
      '股东对公司的所有权是通过股份分配来保证的，股份所有人通过按比例持有股份来确定其所有权。您的公司章程中需要确定您的的初始额定股权数额，该数额须大于零。其实际数字之多寡，由您同各位股东自行斟酌。CORPLAW默认设置了1500股的初始股权。（1500可以被2，3，4，6整除，便于您认股）每股票面价值默认设置为1厘（美金）。（除非如果您所在州要求使用票面价值，CORPLAW不会设定票面价值。在部分州，票面价值高或者股权数目大的公司需支付更高注册费用。',
    shareParValue: '每股票面价值',
    parValue: '票面价值',
    parValueContent:
      '票面价值一家公司股份每股的流通价值。它不需反应出该股权的实际价值，通常会设定一个非常低的面值（比如1美金或者1美分）。每股的票面价值是该股流通时的最低价，并且同等级内股权价格必须统一。并非每个州都会要求设定票面价值。除非您自定义数额，CORPLAW会设定初始额定股权数额1500股（因为1500可以被2，3，4，5，6整除），以及每股票面价值一美分，如果该州不强制则不定票面价值。',
    numberOfShareholders: '股东（1-6）',
    shareholders: '什么是股东',
    shareholdersContent:
      '股东是一家公司的所有人并且是在公司里有最终执行权的人。股东有责任持续选举/任免董事会人员以对公司的长期管理进行监督。一个股东就可以注册公司。一家公司的所有权由公司的股权分配决定，每个股东的股份额与其多有权多寡对应。',
    taxInfo: '税务信息',
    fictitiousName: '公司运营名称',
    businessLocated: '公司的主要业务所在地',
    nameOfResponsibleParty: '责任人',
    noSSN:
      '我没有社保号SSN/税号ITIN（没有SSN和ITN情况下，公司码FEIN申请时间会延长）',
    startedDate: '业务开始/取得日期',
    closedMonth: '财年结束月',
    memberInfo: '公司成员/有限责任公司管理人',
    numberOfMember: '公司成员人数/公司所有人人数（1-6）',
    member: '公司成员',
    individualOrCompany: '该成员为自然人还是一家公司？',
    individual: '自然人',
    company: '公司',
    representative: '公司代表姓名',
    addressOfMember: '该成员地址',
    memberManager: '公司成员/有限责任公司管理人',
    memberManagerContent:
      '有限责任公司（LLC）的管理方式有两种：或者是公司成员管理或者是聘请经理人进行管理。{state}要求公司登记时要注明有限责任公司的管理模式。<br />请选择您打算初成立时使用下列何种管理结构来运营有限责任公司。',
    memberManager1: '该有限责任公司（LLC）会由公司成员管理（最常见）',
    memberManager2: '该有限责任公司（LLC）会由一位经理人管理',
    memberManager3: '该有限责任公司（LLC）会由多位经理人管理',
  },
  agent: {
    title: '公司登记代理人信息',
    content1:
      '公司登记代理人是作为中介用来代收州官方的法律文件和税务往来文件，再将文件传送给您的人。（文件比如诉讼文件的送达，诉讼文件等等）除公司成立相关以外的文件或物件，代理人一概不会代收，垃圾邮件会直接丢弃。',
    content2:
      '在州登记的公司代理人地址必须是一个真实街道地址，美国邮政公司的租赁邮箱不可用于做代理人登记。',
    content3:
      '每个州都要求股份公司和有限责任公司拥有其公司登记代理人（Registered Agent）（其他称谓包括“Resident Agent”, “Statutory Agent”, or “Agent for Service of Process”）',
    content4:
      '您的订单可享受一整年的免费登记代理人服务。次年自动续费登记代理人服务收费$99/年 。',
  },
  optionalService: {
    title: '可选服务',
    initial: '首年年审代理',
    initialHint: '公司注册完成后，必须在90天内完成首年年审。',
    serviceFee: '代理服务费',
    governmentFee: '政府申请费',
    trademark: '商标注册业务',
    trademarkHint: '我们将与你联系以完成商标检索。',
    category: '类目',
  },
  review: {
    title: '查看订单',
  },
  address: {
    address: '地址',
    address2: '地址 2',
    country: '国家',
    state: '州（美国）/省',
    city: '市',
    zip: '邮编',
  },
  payment: {
    title: '付款信息',
    firstName: '名',
    lastName: '姓氏',
    billingAddress: '账单地址',
    cardType: '信用卡或借记卡',
    info: '银行卡付款',
    agreePolicy: '我同意<a href="/disclaimer">免责声明和取消政策</a>',
    pay: '完成并付款',
  },
  order: {
    title: '订单详情',
    total: '合计',
  },
  message: {
    optional: '（选填）',
    choose: '选择',
    ca: '加利福尼亚州',
    de: '特拉华州',
    perYear: '每年',
  },
  pagination: {
    back: '前页',
    next: '后页',
    review: '查看订单',
    update: '更新',
    edit: '编辑',
    payment: '去付款',
    step: '第 {current}/{total} 页',
  },
  header: {
    home: '主页',
    pricing: '报价',
    blog: '官博',
  },
  footer: {
    contactUs: '联系我们',
    tel: '联系电话',
    business: '企业用户',
    technical: '技术支持',
    legal: '隐私&法律',
    terms: '用户条款',
    policy: '隐私政策',
  },
  'Basic Package': '注册公司基础套餐',
  BASIC: '注册公司基础套餐',
  'Overseas Premium Package': '注册公司境外优选套餐',
  OVERSEA: '注册公司境外优选套餐',
  'Premium Package': '注册公司优选套餐',
  PREMIUM: '注册公司优选套餐',
  'Elite Package': '注册公司精品套餐',
  CUSTOM: '注册公司精品套餐',
  'California LLC Filing Fee': '加利福尼亚州有限责任公司（LLC）申请费',
  'California LLC Annual Reports Service': '加利福尼亚州 LLC 年报申请服务',
  'California C-Corp Filing Fee': '加利福尼亚州 C-型公司（C-Corp）申请费',
  'California C-Corp Annual Reports Service':
    '加利福尼亚州 C-Corp 年报申请服务',
  'Delaware LLC Filing Fee': '特拉华州有限责任公司（LLC）申请费',
  'Delaware LLC Annual Reports Service': '特拉华州 LLC 年报申请服务',
  'Delaware C-Corp Filing Fee': '特拉华州 C-型公司（C-Corp）申请费',
  'Delaware C-Corp Annual Reports Service': '特拉华州 C-CORP 年报申请服务',
  'Registered Agent (First Year)': '公司登记代理人服务（首年免费）',
  'Professional Address (First Year)': '商业地址租用服务（首年）',
  'Trademark Registration': '商标注册业务',
  Congratuations: '恭喜你',
  'Your order has been received! Your order id is':
    '我们已经收到你的订单！你的订单号为',
  'Please check your email for order summary.':
    '订单详情已经发送到你的电子邮箱地址，请查收。',
  'The estimated delivery of your certificate will be 30 business days.':
    '你的公司成立证书预计将在30个工作日内交付。',
  'Health care & social assistance': '保健和社会救助',
  'Wholesale-agent / broker': '批发代理商/经纪',
  Construction: '建筑',
  'Rental & leasing': '房地产租赁',
  'Transportation & warehousing': '运输仓储',
  'Accommodation & food service': '食宿服务/旅馆业',
  'Wholesale-other': '批发-其他',
  Retail: '零售',
  'Real estate': '房地产',
  Manufacturing: '制造业',
  'Finance & insurance': '金融&保险',
  'Other (specify)': '其他行业（请注明）',
  'Member-Managed LLC': '公司成员管理的有限责任公司',
  'The vast majority of LLCs are most correctly classified as Member-Managed, and approximately 99.9% of all LLCs filed by CorpLaw are Member-managed LLCs.':
    '此类型在有限责任公司中占绝大多数。在CORPLAW注册的有限责任公司中该类占比约为99.9%。',
  'As the name might indicate, for a Member-Managed LLC, all of the LLC members (owners) are responsible for the day to day management of the LLC, and this is regardless of whether the LLC has one or multiple members.':
    '其名所述，在公司成员管理的有限责任公司里，不论公司成员（公司所有人）人数的多寡，全部对公司内的日常运营负责。',
  'Manager-Managed LLC': '经理人管理的有限责任公司',
  'This is a much less common management structure. For this type of arrangement, one or more (or all) of the LLC members (owners) will take a passive role, and not be involved in the day to day management of the LLC.':
    '该类型公司架构相当罕见。此种情形下，一位或多位（甚至全部）的公司成员退居幕后，不参与有限责任公司的日常事务。',
  'This may take on a few different forms, as follows:':
    '以下是经理人管理的有限责任公司的几种可能模式：',
  'A.) The LLC management will be vested in the members of the LLC - One or more of the LLC members will be actively involved in the day to day management of the LLC, while one or more of the LLC members will take a passive role and not participate in the day to day management of the LLC.':
    '1. 有限责任公司经理人为公司成员：由一位或者多位公司成员主导日常事务，余下成员则退居幕后，不参与其中。',
  'B.) The LLC management will be vested in nonmembers of the LLC - One or more managers that are not members/owners of the LLC may be appointed/hired to be responsible for the day to day management of the LLC.':
    '2. 有限责任公司经理人为非公司成员：一个或多个非公司成员（公司所有人）被雇佣或者指派负责管理公司日常事务。',
  'C.) Both A and B - The management of the LLC may be vested in some combination of both members and nonmembers.':
    '3. 以上情形同时存在：公司成员和非公司成员混同管理有限责任公司。',
  'Order Review': '订单总览',
  'for order id:': '订单号：',
  'Basic Information': '基本信息',
  'Entity Type:': '实体类型',
  'State of Formation:': '注册州',
  'Package:': '服务套餐',
}

export default cn
